#root {
  height: 100vh;
  overflow-y: hidden;
}

#AtlasContentWrapper {
  background-color: #f5f5f5 !important;
}

#app-header {
  background-color: #ffffff !important;
}

#profile-nav-button {
  border: 2px solid rgba(0, 0, 0, 0.05);
}

body {
  margin: 0;
}

.footer {
  border-top: 2px solid rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  justify-content: space-between;
  background-color: #f5f5f5 !important;
}
